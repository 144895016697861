import './App.css';

import About from './pages/about';
import Home from './pages/Home';
import Error from './pages/Error';
import Termandcondition from './pages/Termandcondition';
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/Contact';
import PrivatePolicy from './pages/PrivatePolicy';
import Features from './pages/Features';
import MouseParticles from 'react-mouse-particles';

function App() {
  return (
    <>
    {/* <MouseParticles g={1} color="#000" radius={5} num={16} cull="col,image-wrapper"/> */}
    <MouseParticles g={1} num={10} color="#000" cull="stats,image-wrapper" level={6} />
    <Routes>
       <Route path="/" element={<Home/>} />
       <Route path="/about" element={<About/>} />
       <Route path="/contact-us" element={<Contact/>} />
       <Route path="/terms" element={<Termandcondition/>} />
       <Route path="/policy" element={<PrivatePolicy/>} />
       <Route path="/features" element={<Features/>} />
       <Route element={<Error/>} />
    </Routes>
    </>
  );
}

export default App;
