import React , { Component } from "react";

export default class FactArea extends Component {
    render(){
        return (
        <>
      {/* Start fact Area */}
      <section className="fact-area">
        <div className="container">
          <div className="fact-box">
            <div className="row align-items-center">
              <div className="col single-fact">
                <span className="spanh2">100K+</span>
                <p>Total Downloads</p>
              </div>
              <div className="col single-fact">
                <span className="spanh2">10K+</span>
                <p>Positive Reviews</p>
              </div>
              <div className="col single-fact">
                <span className="spanh2">50K+</span>
                <p>Daily Visitors</p>
              </div>
              <div className="col single-fact">
                <span className="spanh2">0.02%</span>
                <p>Uninstallation Rate</p>
              </div>
              <div className="col single-fact">
                <span className="spanh2">15K+</span>
                <p>Pro User</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End fact Area */}
            
            </>
          );
    }
}