import React , {useState} from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import axios from "axios";
const Contact = () => {

  const [name , setName] = useState('');
  const [email , setEmail] = useState('');
  const [subject , setSubject] = useState('');
  const [message , setMessage] = useState('');

  const [show , setShow] = useState(false);

  // function to update state of name with
    // value enter by user in form
    const handleChange =(e)=>{
      setName(e.target.value);
    }
    // function to update state of age with value
    // enter by user in form
    const handleSubjectChange =(e)=>{
      setSubject(e.target.value);
    }
    // function to update state of email with value
    // enter by user in form
    const handleEmailChange =(e)=>{
      setEmail(e.target.value);
    }
      // function to update state of password with
      // value enter by user in form
    const handleMessageChange =(e)=>{
      setMessage(e.target.value);
    }

    // below function will be called when user
    // click on submit button .
    const handleSubmit=(event)=>{
      
      event.preventDefault();
    const data = {
      name: name,
      email: email,
      subject: subject, 
      message: message,
    }
    axios.post('http://car.webronix.com/api/auth/contact_us', { data })
      .then(res => {
        console.log(res.data.success);
        if(res.data.success === true){
          setShow(true);
        }
      })
 
    }


    return (
        <div>
           <Header></Header>
        {/* start banner Area */}
        <section className="banner-area" style={{ 
      backgroundImage: `url("../img/home-banner-bg.png")` 
    }}>
          <div className="container">
            <div className="row banner-content">
              <div className="col-lg-12 d-flex align-items-center justify-content-between">
                <div className="left-part">
                  <h1>
                    Contact Us
                  </h1>
                  <p>
                  Our team is happy to answer your questions. Fill out the form, and we’ll be in touch as soon as possible.
                  </p>
                </div>
                <div className="right-part">
                  <a href="index.html">home</a>
                  <span className="lnr lnr-chevron-right" />
                  <a href="contact.html">contact</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End banner Area */}
        {/* Start contact-page Area */}
        <section className="contact-page-area section-gap">
          <div className="container">
            <div className="row">
              {/* <div className="map-wrap" style={{width: '100%', height: '445px'}} id="map" /> */}
              <div className="map-wrap" style={{width: '100%', height: '445px'}}><iframe width="100%" height="445" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=114,%20Om%20shubham%20plaza,%20Sector%2016,%20Faridabad,%20Haryana%20121001+(My%20Business%20Name)&amp;t=&amp;z=20&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a></iframe></div>
              <div className="col-lg-4 d-flex flex-column address-wrap">
                <div className="single-contact-address d-flex flex-row">
                  <div className="icon">
                    <span className="lnr lnr-home" />
                  </div>
                  <div className="contact-details">
                    <h5>114, Om shubham plaza</h5>
                    <p>Faridabad, India</p>
                  </div>
                </div>
                <div className="single-contact-address d-flex flex-row">
                  <div className="icon">
                    <span className="lnr lnr-phone-handset" />
                  </div>
                  <div className="contact-details">
                    <h5>+91 8800471360</h5>
                    <p>Mon to Fri 9am to 6 pm</p>
                  </div>
                </div>
                <div className="single-contact-address d-flex flex-row">
                  <div className="icon">
                    <span className="lnr lnr-envelope" />
                  </div>
                  <div className="contact-details">
                    <h5>webronixinfo@gmail.com</h5>
                    <p>Send us your query anytime!</p>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-8">
              {show === true && <p style={{color: 'green'}}>we will contact ASAP.</p>}
                <form className="form-area" id="myForm" onSubmit={(e) => {handleSubmit(e)}}>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <input value={name} placeholder="Enter your name" onChange={(e) => {handleChange(e)}} onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter your name'" className="common-input mb-20 form-control" required type="text" />
                      <input value={email} placeholder="Enter email address" onChange={(e) => {handleEmailChange(e)}}  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter email address'" className="common-input mb-20 form-control" required type="email" />
                      <input value={subject} placeholder="Enter your subject" onChange={(e) => {handleSubjectChange(e)}} onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter your subject'" className="common-input mb-20 form-control" required type="text" />
                    </div>
                    <div className="col-lg-6 form-group">
                      <textarea value={message}  onChange={(e) => {handleMessageChange(e)}} className="common-textarea form-control" name="message" placeholder="Messege" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Messege'" required defaultValue={""} />
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between">
                      <div className="alert-msg" style={{textAlign: 'left'}} />
                      <button className="primary-btn" style={{float: 'right'}}>Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End contact-page Area */}
        <Footer></Footer>
      </div>
    );
}
 
export default Contact;