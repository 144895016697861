import React , { Component } from "react";

export default class Header extends Component {
    render(){
        return (
        <>
           {/* start header Area */}
      <header id="header">
        <div className="container main-menu">
          <div className="row align-items-center justify-content-between d-flex">
            <div id="logo">
              <a href="/">
                <img src="img/logo.png" alt="" />             
                {/* <h1>GadiOwner</h1> */}
                </a>
            </div>
            <nav id="nav-menu-container">
              <ul className="nav-menu">
                {/* <li className="menu-active"><a href="/">Home</a></li> */}
               
              </ul>
            </nav>
          </div>
        </div>
      </header>
      {/* end header Area */}
            
            </>
          );
    }
}