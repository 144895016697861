import React, { useEffect, useRef , Component} from "react";

const VideoArea = () => {

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);


    return (
      <>
      {/* Start about-video Area */}
      <section className="about-video-area section-gap">
         <div className="vdo-bg">
           <div className="container">
             <div className="row align-items-center justify-content-center">
               {/* <div className="col-lg-12 about-video-right justify-content-center align-items-center d-flex relative">
                 <div className="overlay overlay-bg" />
                 <a className="play-btn" href="https://www.youtube.com/watch?v=ARA0AxrnHdM"><img className="img-fluid mx-auto" src="img/play-btn.png" alt="" /></a>
               </div> */}
 
 <video
           style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
           playsInline
           loop
           muted
           alt="All the devices"
           src="https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4"
           ref={videoEl}
         />
             </div>
           </div>
         </div>
       </section>
       {/* End about-video Area */}
             </>
    );
}
 
export default VideoArea;

