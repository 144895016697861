import React , { Component } from "react";

export default class BannerArea extends Component {
    render(){
        return (
        <>
        {/* start banner Area */}
      <section className="home-banner-area" style={{ 
      backgroundImage: `url("../img/home-banner-bg.png")` 
    }}>
        <div className="container">
          <div className="row fullscreen d-flex align-items-center justify-content-between">
            <div className="home-banner-content col-lg-6 col-md-6">
              <h1>
              
             Find Gadi Owner <br />  Of Any Vehicle
              </h1>
              <p>People can contact you if your parked vehicle is causing any problem or is in any emergency.</p>
              <div className="download-button d-flex flex-row justify-content-start">
                <div className="buttons flex-row d-flex">
                  <i className="fa fa-apple" aria-hidden="true" />
                  <div className="desc">
                    <a href="#">
                      <p>
                        <span>Coming soon</span> <br />
                        on App Store
                      </p>
                    </a>
                  </div>
                </div>
                <div className="buttons dark flex-row d-flex">
                  <i className="fa fa-android" aria-hidden="true" />
                  <div className="desc">
                    <a href="#">
                      <p>
                        <span>Available</span> <br />
                        on Play Store
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-img col-lg-4 col-md-6">
              <img className="img-fluid" src="img/banner-img.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* End banner Area */}
            
            </>
          );
    }
}