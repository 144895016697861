import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import BannerArea from '../components/banner_area';
import FactArea from '../components/fact_area';
import FeatureArea from '../components/feature_area';
import VideoArea from '../components/video_area';
import ScreenShot from '../components/screenshot';
import About from '../components/about';
const Home = () => {
    return (
    <div className="App">
      <Header></Header>
      <BannerArea></BannerArea>
      <ScreenShot></ScreenShot>
      <FactArea></FactArea>
      <FeatureArea></FeatureArea>
      <About></About>
      <VideoArea></VideoArea>
      <Footer></Footer>
    </div>
  );
}
 
export default Home;