import React , { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
export default class ScreenShot extends Component {
    render(){
        return (
        <>
 {/* Start Screenshot Area */}

 <section className="screenshot-area section-gap-top">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2>Featured Screens</h2>
                <p>We provide the solution for the issue regarding the parked vehicles. You will contact the owner and inform them about the vehicle issue.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
          <OwlCarousel  loop margin={20} nav autoplay autoplayTimeout={1500}>
  
  <div className='item'>
              <img className="img-fluid" src="img/screenshots/1.png" alt="" />
         
  </div>
  <div className='item'>
              <img className="img-fluid" src="img/screenshots/2.png" alt="" />
      
  </div>
  <div className='item'>
              <img className="img-fluid" src="img/screenshots/3.png" alt="" />
    
  </div>
  <div className='item'>
              <img className="img-fluid" src="img/screenshots/4.png" alt="" />
     
  </div>
  <div className='item'>
              <img className="img-fluid" src="img/screenshots/5.png" alt="" />
     
  </div>
  <div className='item'>
              <img className="img-fluid" src="img/screenshots/6.png" alt="" />
     
  </div>
  

</OwlCarousel>
          </div>
        </div>
      </section>
      {/* End Screenshot Area */}
            </>
          );
    }
}