import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const Features = () => {
    return (
        <div>
           <Header></Header>
           {/* start banner Area */}
        <section className="banner-area" style={{ 
      backgroundImage: `url("../img/home-banner-bg.png")` 
    }}>
          <div className="container">
            <div className="row banner-content">
              <div className="col-lg-12 d-flex align-items-center justify-content-between">
                <div className="left-part">
                  <h1>
                    Features Area
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua.
                  </p>
                </div>
                <div className="right-part">
                  <a href="index.html">home</a>
                  <span className="lnr lnr-chevron-right" />
                  <a href="contact.html">Features</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End banner Area */}
           {/* start banner Area */}
           <section className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 home-about-left">
              <img className="img-fluid" src="img/register.gif" alt="" style={{height: '568px', width: '279px'}} />
            </div>
            <div className="offset-lg-1 col-lg-5 home-about-right" >
              <h1>
              First Please Sign Up <br /> It's totally free 
              </h1>
              <p>
              It is totally a free app for all users.<br />
                    - First, Please enter your phone number.<br />
                    - second, Verify your phone number.<br />
                    - Third, enter your vehicle number, choose your vehicle type and enter your name.<br />
                    - Now your sign-up is complete.
              </p>
              <a className="primary-btn text-uppercase" href="#">Get Details</a>
            </div>
            <div className="col-lg-6 home-about-right home-about-right2">
              <h1>
                If someone's  parked <br />vehicle causing any <br />problem
              </h1>
              <p>
              - In the first screen, Enter the vehicle number and tap Next.<br />
              - Please choose the option of why you want to inform the vehicle owner.<br />
              - Now click on the ping button to notify the user.<br />
              - When the problem is resolved, tap on the done button.<br />
              - Or if the problem is not solved, you can ping the user 5 times and each ping takes 25 seconds. 
              </p>
              <div className="download-button d-flex flex-row justify-content-start">
                <div className="buttons flex-row d-flex">
                  <i className="fa fa-apple" aria-hidden="true" />
                  <div className="desc">
                    <a href="#">
                      <p>
                        <span>Coming soon</span> <br />
                        on App Store
                      </p>
                    </a>
                  </div>
                </div>
                <div className="buttons dark flex-row d-flex">
                  <i className="fa fa-android" aria-hidden="true" />
                  <div className="desc">
                    <a href="#">
                      <p>
                        <span>Available</span> <br />
                        on Play Store
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 home-about-left">
              <img className="img-fluid" src="img/home.gif" alt="" style={{height: '568px', width: '279px'}}/>
            </div>
            <div className="col-lg-5 home-about-left">
              <img className="img-fluid" src="img/emergency.gif" alt="" style={{height: '568px', width: '279px'}}/>
            </div>
            <div className="offset-lg-1 col-lg-5 home-about-right">
              <h1>
                If there is any emergency <br />People can Contact <br /> the vehicle owner's <br /> family and friends
              </h1>
              <p>
              The first step is for you, please fill in the contact details of your family and friends<br />
                - On the first screen, Click on the SOS button<br />
                - Then fill in the emergency contact details and tap SAVE.<br />

                The second step is for emergency contact<br />
                - Please enter their vehicle number<br />
                - Then click on the upper SOS button<br />
                - And you can see their emergency contact details. Now you can contact them.<br />
              </p>
              <a className="primary-btn text-uppercase" href="#">Get Details</a>
            </div>
          </div>
        </div>
      </section>
      {/* End Sample Area */}
      <Footer></Footer>
      </div>
    );
}
 
export default Features;