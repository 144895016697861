import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const Termandcondition = () => {
    return (
        <div>
           <Header></Header>
           {/* start banner Area */}
        <section className="banner-area" style={{ 
      backgroundImage: `url("../img/home-banner-bg.png")` 
    }}>
          <div className="container">
            <div className="row banner-content">
              <div className="col-lg-12 d-flex align-items-center justify-content-between">
                <div className="left-part">
                  <h1>
                  Terms and Conditions
                  </h1>
                 
                </div>
                <div className="right-part">
                  <a href="index.html">home</a>
                  <span className="lnr lnr-chevron-right" />
                  <a href="about.html">Terms and Conditions</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End banner Area */}
 {/* Start Sample Area */}
 <section className="sample-text-area">
        <div className="container">
          <p className="sample-text">
         <h1><b>General Terms</b> </h1>
         <br />
<p>By accessing and placing an order with GadiOwner, we assume you or accept these terms and conditions in full.
     Do not continue to use gadiowner.com website if you do not accept all of the terms and conditions stated on this page.
      These terms and conditions outline the rules and regulations for the use of gadiowner.com Website.</p>


      <h1><b>License</b> </h1>
      <br />
<p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.

<br /><br /> Unless otherwise stated, gadiowner.com and/or it’s licensors own the intellectual property rights for all material on gadiowner.com. All intellectual property rights are reserved. You may view and/or print pages from https:// gadiowner.com / for your own personal use subject to restrictions set in these terms and conditions.
</p>


      <h1><b>Cookies</b> </h1>
      <br />
<p>we use “cookies” to identify the area of our website that you have visited. By using gadiowner.com website you consent to the use of cookies in accordance with gadiowner.com privacy policy.<br /><br />

Most of the modern day interactive web sites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. We never place personally identifiable information’s in cookies. Some of our affiliate / advertising partners may also use cookies.
</p>


      <h1><b>Restrictions </b> </h1>
      <br />
<p>You agree not to, and you will not permit other to; <br /><br />

License, sell, rent, lease, assign, distribute, transmit, host, outsource, 
disclose otherwise commercially exploit the service or make the platform available to any third party. <br /><br />

Remove or modify, make the derivative work of, dissemble, decrypt, reverse compile or reverse engineer any part of services. <br /><br />

 Sell, rent or sub-license material from https:// gadiowner.com
Republish material from https:// gadiowner.com
Redistribute content from gadiowner.com (unless content is specifically made for redistribution).
</p>


      <h1><b>Hyper linking to our Content</b> </h1>
      <br />
<p>The following organizations may link to our Web site without prior written approval:<br />
Government agencies;<br />
Search engines;<br />
News organizations;<br />
Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Web sites of other listed businesses; and
System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.<br />
We may consider and approve in our sole discretion other link requests from the following types of organizations:<br />
commonly-known consumer and/or business information sources such as Chambers of Commerce, American Automobile Association, AARP and Consumers Union;<br />
dot.com community sites;<br />
associations or other groups representing charities, including charity giving sites,
online directory distributors;<br />
internet portals;<br />
accounting, law and consulting firms whose primary clients are businesses; and
educational institutions and trade associations.<br />
We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of GADIOWNER.COM ; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.<br />

These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and it products or services; and (c) fits within the context of the linking party's site.<br />

If you are among the organizations listed in paragraph 2 above and are interested in linking to our website, you must notify us by sending an e-mail to care@13Clients.com. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.<br />

Approved organizations may hyperlink to our Web site as follows:<br />

By use of our corporate name; or<br />
By use of the uniform resource locator (Web address) being linked to; or<br />
By use of any other description of our Web site or material being linked to that makes sense within the context and format of content on the linking party's site.<br />
No use of GADIOWNER.COM logo or other artwork will be allowed for linking absent a trademark license agreement.<br />

</p>

<h1><b>I frames</b> </h1>

<p>without prior approval and express written permission, you may not create frames around our Web pages or use other techniques that alter in any way the visual presentation or appearance of our Web site.</p>



      <h1><b>Reservation of Rights</b> </h1>

<p>we reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</p>



      <h1><b>Removal of links from our website</b> </h1>

<p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.<br /><br />

Whilst we Endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.
</p>



      <h1><b>PROMOTONS</b> </h1>

<p>We may; from time to time, include contests, promotion, sweepstakes, or other activities (“promotions”) that require you to submit material or information concerning yourself. Please note that all the promotions may be governed by the separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location.  </p>



      <h1><b>Content Liability</b> </h1>

<p>we shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights. Some state/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>


      <h1><b>Disclaimer</b> </h1>

<p>We are not responsible for any content, code or any other imprecision. To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:<br /><br />

Our services and its contents are provided”as is” and “as available” without any warranty or representations of any kind, weather express or implied. Limit or exclude our or your liability for death or personal injury resulting from negligence;
limit or exclude our or your liability for fraud or fraudulent misrepresentation;
limit any of our or your liabilities in any way that is not permitted under applicable law; or
exclude any of our or your liabilities that may not be excluded under applicable law.
The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.<br /><br />

To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature. Price and availability information is subject to change without notice.
</p>


      <h1><b>Contact Us</b> </h1>

<p>Don’t hesitate to contact us if you have any questions.<br />

Via Email: webronixinfo@gmail.com<br />
Via this link : www.gadiowner.com<br />
</p>



          </p>
        </div>
      </section>
      {/* End Sample Area */}
      <Footer></Footer>
      </div>
    );
}
 
export default Termandcondition;