import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
const About = () => {
    return (
        <div>
           <Header></Header>
        {/* start banner Area */}
        <section className="banner-area" style={{ 
      backgroundImage: `url("../img/home-banner-bg.png")` 
    }}>
          <div className="container">
            <div className="row banner-content">
              <div className="col-lg-12 d-flex align-items-center justify-content-between">
                <div className="left-part">
                  <h1>
                    About Us
                  </h1>
                  <p>
                  GadiOwner is a free application on Google Playstore and Applestore. We made this application for problems related to parked vehicles. Where you can contact the vehicle owner. If their vehicle is causing a problem or is in any problem. You can also contact the family of the vehicle owner in case of an emergency. Our aim is only to help vehicle owners.
                  <br /><br />On this platform, you can contact the vehicle owner. If their vehicle is causing a problem or is in any problem. You can also contact the family of the vehicle owner in case of an emergency.
                  </p>
                </div>
                <div className="right-part">
                  <a href="index.html">home</a>
                  <span className="lnr lnr-chevron-right" />
                  <a href="about.html">about</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End banner Area */}
        {/* Start fact Area */}
        <section className="fact-area section-gap-top">
          <div className="container">
            <div className="fact-box">
              <div className="row align-items-center">
                <div className="col single-fact">
                  <h2>100K+</h2>
                  <p>Total Downloads</p>
                </div>
                <div className="col single-fact">
                  <h2>10K+</h2>
                  <p>Positive Reviews</p>
                </div>
                <div className="col single-fact">
                  <h2>50K+</h2>
                  <p>Daily Visitors</p>
                </div>
                <div className="col single-fact">
                  <h2>0.02%</h2>
                  <p>Uninstallation Rate</p>
                </div>
                <div className="col single-fact">
                  <h2>15K+</h2>
                  <p>Pro User</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End fact Area */}
        {/* Start feature Area */}
        <section className="feature-area section-gap-top">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2>Unique Features</h2>
                <p>With the help of GadiOwner, people can contact you via notification, in case your vehicle is creating any
                   issues or is in any emergency.

                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-feature">
                <a href="#" className="title">
                  <span className="lnr lnr-car" />
                  <h3>Wrong Parking</h3>
                </a>
                <p>
                If you are troubled by the wrong car parking, then this app will help you contact the vehicle owner.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature">
                <a href="#" className="title">
                  <span className="lnr lnr-phone" />
                  <h3>Emergency Situation</h3>
                </a>
                <p>
                People can help the vehicle owner if someone's vehicle is in any trouble.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature">
                <a href="#" className="title">
                  <span className="lnr lnr-link" />
                  <h3>Free Registration</h3>
                </a>
                <p>
                This app is free for all users. You Just sign up and use the app anywhere in India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* End feature Area */}
        {/* Start about Area */}
        <section className="about-area">
          <div className="container">
            <div className="row align-items-center">
            <div className="col-lg-5 home-about-left">
              <img className="img-fluid" src="img/register.gif" alt="" style={{height: '568px', width: '279px'}} />
            </div>
            <div className="offset-lg-1 col-lg-5 home-about-right" >
              <h1>
              First Please Sign Up <br /> It's totally free 
              </h1>
              <p>
              It is totally a free app for all users.<br />
                    - First, Please enter your phone number.<br />
                    - second, Verify your phone number.<br />
                    - Third, enter your vehicle number, choose your vehicle type and enter your name.<br />
                    - Now your sign-up is complete.
              </p>
              <a className="primary-btn text-uppercase" href="#">Get Details</a>
            </div>
            </div>
          </div>
        </section>
        {/* End about Area */}
        {/* Start Testimonials Area */}
        <section className="testimonials-area about-page section-gap-bottom">
          <div className="container">
            <div className="testi-slider owl-carousel" data-slider-id={1}>
              <div className="item">
                <div className="testi-item">
                  <img src="img/quote.png" alt="" />
                  <h4>Fanny Spencer</h4>
                  <ul className="list">
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      As conscious traveling Paup ers we must always be oncerned about our dear Mother Earth. If you think about it,
                      you travel
                      across her face <br /> and She is the host to your journey.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testi-item">
                  <img src="img/quote.png" alt="" />
                  <h4>Fanny Spencer</h4>
                  <ul className="list">
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      As conscious traveling Paup ers we must always be oncerned about our dear Mother Earth. If you think about it,
                      you travel
                      across her face <br /> and She is the host to your journey.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testi-item">
                  <img src="img/quote.png" alt="" />
                  <h4>Fanny Spencer</h4>
                  <ul className="list">
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      As conscious traveling Paup ers we must always be oncerned about our dear Mother Earth. If you think about it,
                      you travel
                      across her face <br /> and She is the host to your journey.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testi-item">
                  <img src="img/quote.png" alt="" />
                  <h4>Fanny Spencer</h4>
                  <ul className="list">
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                    <li><a href="#"><i className="fa fa-star" /></a></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      As conscious traveling Paup ers we must always be oncerned about our dear Mother Earth. If you think about it,
                      you travel
                      across her face <br /> and She is the host to your journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-thumbs d-flex justify-content-center" data-slider-id={1}>
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t1.jpg" alt="" />
                </div>
                <div className="overlay overlay-grad" />
              </div>
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t2.jpg" alt="" />
                </div>
                <div className="overlay overlay-grad" />
              </div>
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t3.jpg" alt="" />
                </div>
                <div className="overlay overlay-grad" />
              </div>
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t4.jpg" alt="" />
                </div>
                <div className="overlay overlay-grad" />
              </div>
            </div>
          </div>
        </section>
        {/* End Testimonials Area */}
        <Footer></Footer>
      </div>
    );
}
 
export default About;