import React , {Component } from "react";

export default class About extends Component {

    render(){
        return(
            <>
             {/* Start about Area */}
      <section className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 home-about-left">
              <img className="img-fluid" src="img/register.gif" alt="" style={{height: '568px', width: '279px'}} />
            </div>
            <div className="offset-lg-1 col-lg-5 home-about-right" >
              <span className="spanh1">
              First Please Sign Up <br /> It's totally free 
              </span>
              <p>
              <ul className="unordered-list">
              <li>It is totally a free app for all users.<br /></li>
              <li>First, Please enter your phone number.<br /></li>  
              <li>second, Verify your phone number.<br /></li>   
              <li>Third, enter your vehicle number, choose your vehicle type and enter your name.<br /></li>  
              <li>Now your sign-up is complete.</li>  
            </ul>
              </p>
              <a className="primary-btn text-uppercase" href="#">Get Details</a>
            </div>
            <div className="col-lg-6 home-about-right home-about-right2">
            <span className="spanh1">
                If someone's  parked <br />vehicle causing any <br />problem
              </span>
              <p>
             
							<ul className="unordered-list">
								<li>In the first screen, Enter the vehicle number and tap Next.<br /></li>
								<li>Please choose the option of why you want to inform the vehicle owner.<br /></li>
								<li>Now click on the ping button to notify the user.<br /></li>
								<li>When the problem is resolved, tap on the done button.<br /></li>
								<li>Or if the problem is not solved, you can ping the user 5 times and each ping takes 25 seconds. </li>
							
							</ul>
              </p>
              <div className="download-button d-flex flex-row justify-content-start">
                <div className="buttons flex-row d-flex">
                  <i className="fa fa-apple" aria-hidden="true" />
                  <div className="desc">
                    <a href="#">
                      <p>
                        <span>Coming soon</span> <br />
                        on App Store
                      </p>
                    </a>
                  </div>
                </div>
                <div className="buttons dark flex-row d-flex">
                  <i className="fa fa-android" aria-hidden="true" />
                  <div className="desc">
                    <a href="#">
                      <p>
                        <span>Available</span> <br />
                        on Play Store
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 home-about-left">
              <img className="img-fluid" src="img/home.gif" alt="" style={{height: '568px', width: '279px'}}/>
            </div>
            <div className="col-lg-5 home-about-left">
              <img className="img-fluid" src="img/emergency.gif" alt="" style={{height: '568px', width: '279px'}}/>
            </div>
            <div className="offset-lg-1 col-lg-5 home-about-right">
            <span className="spanh1">
                If there is any emergency <br />People can Contact <br /> the vehicle owner's <br /> family and friends
              </span>
              <p>
              <ul className="unordered-list">
              <li>The first step is for you, please fill in the contact details of your family and friends.<br /></li>
              <li>On the first screen, Click on the SOS button.<br /></li>  
              <li>Then fill in the emergency contact details and tap SAVE.<br /></li>   
              <li>The second step is for emergency contact.<br /></li>  
              <li>Please enter their vehicle number.<br /></li>  
              <li>Then click on the upper SOS button.<br /></li>  
              <li>And you can see their emergency contact details. Now you can contact them.<br /></li>  
            </ul>
              </p>
              <a className="primary-btn text-uppercase" href="#">Get Details</a>
            </div>
          </div>
        </div>
      </section>
      {/* End about Area */}
            </>
        );
    }
}