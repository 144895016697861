import React , { Component } from "react";

export default class FeatureArea extends Component {
    render(){
        return (
        <>
        {/* Start feature Area */}
      <section className="feature-area section-gap-top">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2>Unique Features</h2>
                <p>With the help of GadiOwner, people can contact you via notification, in case your vehicle is creating any
                   issues or is in any emergency.

                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-feature">
                <a href="#" className="title">
                  <span className="lnr lnr-car" />
                  <h3>Wrong Parking</h3>
                </a>
                <p>
                If you are troubled by the wrong car parking, then this app will help you contact the vehicle owner.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature">
                <a href="#" className="title">
                  <span className="lnr lnr-phone" />
                  <h3>Emergency Situation</h3>
                </a>
                <p>
                People can help the vehicle owner if someone's vehicle is in any trouble.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature">
                <a href="#" className="title">
                  <span className="lnr lnr-link" />
                  <h3>Free Registration</h3>
                </a>
                <p>
                This app is free for all users. You Just sign up and use the app anywhere in India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End feature Area */}
            </>
          );
    }
}