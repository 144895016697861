import React from 'react';
 
const Error = () => {
    return (
       <div>
          <h1>Error</h1>
           <p>Error page</p>
       </div>
    );
}
 
export default Error;