import React , {Component} from "react";

export default class Footer extends Component {
 
    render() {
        return(
                 <>
         {/* Start Footer Area */}
    
      <footer className="footer-area section-gap">
		<div className="container">
			<div className="row">
				<div className="col-lg-2 col-md-6 single-footer-widget">
					<h4>Top Products</h4>
					<ul>
          <li><a href="/about">About Us</a></li>
                <li><a href="/features">Features</a></li>
					  <li><a href="/contact-us">Contact Us</a></li>
					</ul>
				</div>
				<div className="col-lg-2 col-md-6 single-footer-widget">
					<h4>Quick Links</h4>
					<ul>
            
                <li><a href="/policy">Private Policy</a></li>
                <li><a href="/terms">Terms of Service</a></li>
              
					</ul>
				</div>
				{/* <div className="col-lg-2 col-md-6 single-footer-widget">
					<h4>Features</h4>
					<ul>
						<li><a href="#">Jobs</a></li>
						<li><a href="#">Brand Assets</a></li>
						<li><a href="#">Investor Relations</a></li>
						<li><a href="#">Terms of Service</a></li>	
					</ul>
				</div>
				<div className="col-lg-2 col-md-6 single-footer-widget">
					<h4>Resources</h4>
					<ul>
						<li><a href="#">Guides</a></li>
						<li><a href="#">Research</a></li>
						<li><a href="#">Experts</a></li>
						<li><a href="#">Agencies</a></li>
					</ul>
				</div> */}
			
			<div className="footer-bottom row align-items-center">
				<p className="footer-text m-0 col-lg-6 col-md-12">   Copyright @2022 All rights reserved by <a href="https://gadiowner.com" target="_blank">GadiOwner</a></p>
				<div className="col-lg-6 col-md-6 social-link">
					<div className="download-button d-flex flex-row justify-content-end">
						<div className="buttons gray flex-row d-flex">
							<i className="fa fa-apple" aria-hidden="true"></i>
							<div className="desc">
								<a href="#">
									<p>
										<span>Available</span>  <br />
										on App Store
									</p>
								</a>
							</div>
						</div>
						<div className="buttons gray flex-row d-flex">
							<i className="fa fa-android" aria-hidden="true"></i>
							<div className="desc">
								<a href="#">
									<p>
										<span>Available</span> <br />
										on Play Store
									</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
	</footer>

      
      {/* End Footer Area */}
      </>
        );
    }
}